import React from "react";
import { Link } from "react-router-dom";
import { useParallax } from "react-scroll-parallax";

const AboutAreaTwo = () => {
  const parallax = useParallax({
    translateY: [-24, 24],
    rootMargin: 0,
  });

  return (
    <section className="about-area-two">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 order-0 order-lg-2">
            <div className="about-img-two text-center">
              <img src="/img/images/success_img.png" alt="" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="about-content-two">
              <div className="section-title white-title title-style-two mb-30">
                <span className="sub-title">Tu éxito es nuestra prioridad</span>
                <h2 className="title">
                  GRUPO CUSTOM <br />
                </h2>
              </div>
              <p>
                Con años de experiencia en el sector, nuestro equipo de expertos
                está comprometido a brindarte soluciones tecnológicas
                personalizadas que impulsen tu negocio al siguiente nivel. Desde
                la integración de sistemas hasta la creación de estrategias
                digitales efectivas, estamos aquí para garantizar que tu empresa
                esté equipada para enfrentar los desafíos del mundo digital.
                <br />
                <br />
                Confía en nosotros para transformar tu visión en realidad con un
                enfoque profesional y atención a cada detalle.
              </p>
              <Link to="https://wa.link/r6idiv">
                <span className="arrow">
                  <img src="/img/icon/right_arrow.svg" alt="" />
                </span>
                <span className="text">Agenda Tu Consulta Gratuita</span>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="about-shape-wrap">
        <img
          src="/img/images/h2_about_shape01.png"
          alt=""
          className="shape-one"
          ref={parallax.ref}
        />
        <img
          src="/img/images/h2_about_shape02.png"
          alt=""
          className="shape-two"
        />
        <img
          src="/img/images/h2_about_shape03.png"
          alt=""
          className="shape-three"
        />
      </div>
    </section>
  );
};

export default AboutAreaTwo;
