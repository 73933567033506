import React from "react";
import ServicesAreaTwoItem from "./ServicesAreaTwoItem";

const ServicesAreaTwo = () => {
  const services_two_items = [
    {
      src: "/img/icon/inner_services_icon02.png",
      url: "https://wa.link/r6idiv",
      title: "Desarrollo Web",
      desc: "Creación y diseño de sitios web personalizados, corporativas y portales informativos para profesionales y empresas como abogados, doctores, contadores, consultores y pequeños negocios.",
    },
    {
      src: "/img/icon/h3_services_icon02.png",
      url: "https://wa.link/r6idiv",
      title: "Plataformas de Venta en Línea",
      desc: "Creación y gestión de tiendas en internet donde los clientes pueden comprar productos o servicios, incluyendo herramientas para manejar pagos y productos.",
    },
    {
      src: "/img/icon/inner_services_icon01.png",
      url: "https://wa.link/r6idiv",
      title: "Marketing Digital",
      desc: "Estrategias para promover productos y servicios en internet, como mejorar el ranking en buscadores, publicidad en redes sociales y anuncios en línea.",
    },
    {
      src: "/img/icon/inner_services_icon05.png",
      url: "https://wa.link/r6idiv",
      title: "Automatización de Procesos",
      desc: "Sistemas informáticos que facilita la realización de tareas repetitivas y procesos internos, mejorando la eficiencia y reduciendo el trabajo manual.",
    },
    {
      src: "/img/icon/h3_services_icon03.png",
      url: "https://wa.link/r6idiv",
      title: "Sistemas de Gestión de Relación con el Cliente (CRM)",
      desc: "Software que ayuda a gestionar las interacciones con los clientes, facilitando el seguimiento de ventas y el servicio al cliente.",
    },
    {
      src: "/img/icon/inner_two_services_icon04.png",
      url: "https://wa.link/r6idiv",
      title: "Sistemas de Gestión Empresarial (ERP)",
      desc: "Software que ayuda a administrar todos los aspectos de una empresa, como finanzas, inventario, personal y logística, en un solo lugar.",
    },
    {
      src: "/img/icon/services_icon02.png",
      url: "https://wa.link/r6idiv",
      title: "Soluciones de Ciberseguridad",
      desc: "Herramientas y prácticas para proteger la información y los sistemas de la empresa contra ataques y amenazas en línea.",
    },
    {
      src: "/img/icon/services_icon01.png",
      url: "https://wa.link/r6idiv",
      title: "Servicios de Cloud Computing",
      desc: "Uso de internet para almacenar datos, ejecutar aplicaciones y gestionar recursos sin necesidad de hardware físico en la empresa.",
    },
  ];

  return (
    <section className="services-area-two">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="section-title title-style-two white-title mb-65 text-center">
              <span className="sub-title">Algunos de Nuestros</span>
              <h2 className="title">Servicios</h2>
              Soluciones personalizadas para tu negocio: Desde páginas web hasta
              sistemas a medida, adaptamos nuestras soluciones a tus necesidades
              específicas.
            </div>
          </div>
        </div>

        <div className="row justify-content-center">
          {services_two_items.map((x, index) => (
            <div key={index} className="col-xl-3 col-lg-4 col-md-6 col-sm-8">
              <ServicesAreaTwoItem item={x} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServicesAreaTwo;
