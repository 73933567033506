import React from "react";
import { useParallax } from "react-scroll-parallax";

const WorkArea = () => {
  const parallax = useParallax({
    translateY: [-24, 24],
    rootMargin: 0,
  });

  const work_list = [
    {
      src: "/img/icon/counter_icon02.png",
      title: "Creación del Concepto",
      desc: "Definimos los objetivos y requisitos específicos del proyecto. En esta fase, nos enfocamos en comprender tus necesidades, analizar el mercado y desarrollar una estrategia técnica clara.",
    },
    {
      src: "/img/icon/inner_counter_icon03.png",
      title: "Desarrollo e Implementación",
      desc: "En esta fase convertimos el concepto en una solución funcional. Esto incluye la programación, la integración de sistemas y la creación de todas las características necesarias para el funcionamiento del proyecto.",
    },
    {
      src: "/img/icon/inner_counter_icon02.png",
      title: "Pruebas y Optimización",
      desc: "Finalmente, en la etapa de Pruebas y Optimización, evaluamos la solución para identificar y corregir cualquier problema.",
    },
  ];

  return (
    <section className="work-area pt-110 pb-120">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="work-img text-center">
              <img src="/img/images/h2_about_img.png" alt="" />
            </div>
          </div>

          <div className="col-lg-6">
            <div className="work-content">
              <div className="section-title title-style-two white-title mb-30">
                <span className="sub-title">Nuestro Proceso de Trabajo</span>
                <h2 className="title">Pasos de Trabajo</h2>
              </div>

              <div className="work-list">
                <ul className="list-wrap">
                  {work_list.map((x, index) => (
                    <li key={index}>
                      <div className="icon">
                        <img src={x.src} alt="" />
                      </div>
                      <div className="content">
                        <h4 className="title">{x.title}</h4>
                        <p>{x.desc}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="work-shape-wrap">
        <img src="/img/images/work_shape01.png" alt="" />
        <img
          src="/img/images/work_shape02.png"
          alt=""
          // data-parallax='{"y" : 80 }'
          ref={parallax.ref}
        />
        <img src="/img/images/work_shape03.png" alt="" />
        <img src="/img/images/work_shape04.png" alt="" />
        <img src="/img/images/work_shape05.png" alt="" />
      </div>
    </section>
  );
};

export default WorkArea;
