import React from "react";
import { Link } from "react-router-dom";

const PricingArea = () => {
  return (
    <section className="pricing-area pb-90">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="section-title title-style-two text-center white-title mb-50">
              <h2 className="title">Planes y Precios</h2>
              <p>Selecciona el plan ideal para ti</p>
            </div>
          </div>
        </div>

        <div className="pricing-item-wrap">
          <div className="pricing-tab">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="month-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#month"
                  type="button"
                  role="tab"
                  aria-controls="month"
                  aria-selected="true"
                >
                  Mensual
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="year-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#year"
                  type="button"
                  role="tab"
                  aria-controls="year"
                  aria-selected="false"
                >
                  Anual
                </button>
              </li>
            </ul>
          </div>

          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane show active"
              id="month"
              role="tabpanel"
              aria-labelledby="month-tab"
            >
              <div className="row g-0 align-items-center justify-content-center">
                <div className="col-lg-4 col-md-6">
                  <div className="pricing-item">
                    <div className="pricing-icon">
                      <img src="/img/icon/pricing_icon01.png" alt="" />
                    </div>
                    <div className="pricing-top">
                      <h2 className="title">Básico</h2>
                      <p>Ideal para comenzar</p>
                    </div>
                    <div className="pricing-price">
                      <h2 className="price">
                        <span>S/</span>99<strong>/mes</strong>
                      </h2>
                    </div>
                    <div className="pricing-list">
                      <h4 className="title">Incluye:</h4>
                      <ul className="list-wrap">
                        <li>
                          <i className="far fa-check"></i>Página web informativa
                        </li>
                        <li>
                          <i className="far fa-check"></i>Diseño adaptable a
                          móviles
                        </li>
                        <li>
                          <i className="far fa-check"></i>Seguridad SSL (https)
                        </li>
                        <li>
                          <i className="far fa-check"></i>Enlaces a redes
                          sociales
                        </li>
                        <li>
                          <i className="far fa-check"></i>1 correo corporativo
                        </li>
                        <li>
                          <i className="far fa-check"></i>Creación de redes
                          sociales
                        </li>
                        <li>
                          <i className="far fa-check"></i>Diseño de logo básico
                        </li>
                        <li>
                          <i className="far fa-check"></i>Registro negocio en
                          Google Maps
                        </li>
                        <li>
                          <i className="far fa-check"></i>Integración con
                          WhatsApp
                        </li>
                        <li>
                          <i className="far fa-check"></i>Optimización SEO
                        </li>
                        <li>
                          <i className="far fa-check"></i>Capacitación de uso
                          del sitio web
                        </li>
                      </ul>
                    </div>
                    <div className="pricing-btn">
                      <Link
                        to="https://www.mercadopago.com.pe/subscriptions/checkout?preapproval_plan_id=2c9380849146ff1601914a468c390169"
                        className="btn"
                      >
                        Seleccionar este plan
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6">
                  <div className="pricing-item active">
                    <div className="pricing-icon">
                      <img src="/img/icon/pricing_icon02.png" alt="" />
                    </div>
                    <div className="pricing-top">
                      <h2 className="title">Estándar</h2>
                      <p style={{ color: "#e68839", fontWeight: "bold" }}>
                        Más Popular
                      </p>
                    </div>
                    <div className="pricing-price">
                      <h2 className="price">
                        <span>S/</span>149<strong>/mes</strong>
                      </h2>
                    </div>
                    <div className="pricing-list">
                      <h4 className="title">Incluye:</h4>
                      <ul className="list-wrap">
                        <li>
                          <i className="far fa-check"></i>Más Todo lo del plan
                          Básico
                        </li>
                        <li>
                          <i className="far fa-check"></i>Dominio.com gratis por
                          1 año
                        </li>
                        <li>
                          <i className="far fa-check"></i>Tienda en línea
                        </li>
                        <li>
                          <i className="far fa-check"></i>Gestión de productos
                        </li>
                        <li>
                          <i className="far fa-check"></i>Hasta 5 correos
                          corporativos
                        </li>
                        <li>
                          <i className="far fa-check"></i>Campaña básica de
                          publicidad
                        </li>
                        <li>
                          <i className="far fa-check"></i>Creación de pixel de
                          facebook
                        </li>
                        <li>
                          <i className="far fa-check"></i>Google Analytics
                        </li>
                        <li>
                          <i className="far fa-check"></i>Cupones y descuentos
                        </li>
                        <li>
                          <i className="far fa-check"></i>Configuración de chat
                          en vivo
                        </li>
                        <li>
                          <i className="far fa-check"></i>Integración de pago
                          locales (según país - Tarjetas, Yape)
                        </li>
                      </ul>
                    </div>
                    <div className="pricing-btn">
                      <Link
                        to="https://www.mercadopago.com.pe/subscriptions/checkout?preapproval_plan_id=2c9380849146ff8b01914a4b86c5017a"
                        className="btn"
                      >
                        Seleccionar este plan
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6">
                  <div className="pricing-item">
                    <div className="pricing-icon">
                      <img src="/img/icon/pricing_icon03.png" alt="" />
                    </div>
                    <div className="pricing-top">
                      <h2 className="title">Premium</h2>
                      <p>Soluciones completas</p>
                    </div>
                    <div className="pricing-price">
                      <h2 className="price">
                        <span>S/</span>199<strong>/mes</strong>
                      </h2>
                    </div>
                    <div className="pricing-list">
                      <h4 className="title">Incluye:</h4>
                      <ul className="list-wrap">
                        <li>
                          <i className="far fa-check"></i>Más Todo lo del plan
                          Estándard
                        </li>
                        <li>
                          <i className="far fa-check"></i>Control de inventario
                        </li>
                        <li>
                          <i className="far fa-check"></i>Sistema de punto de
                          venta (POS)
                        </li>
                        <li>
                          <i className="far fa-check"></i>Gestión de clientes
                        </li>
                        <li>
                          <i className="far fa-check"></i>Reportes detallados
                        </li>
                        <li>
                          <i className="far fa-check"></i>Correos corporativos
                          ilimitados
                        </li>
                        <li>
                          <i className="far fa-check"></i>Diseño de logo
                          avanzado
                        </li>
                        <li>
                          <i className="far fa-check"></i>Google Ads y Facebook
                          Ads
                        </li>
                        <li>
                          <i className="far fa-check"></i>Asesoría marketing
                          digital
                        </li>
                        <li>
                          <i className="far fa-check"></i>Sistema de lealtad
                          para clientes
                        </li>
                        <li>
                          <i className="far fa-check"></i>Soporte 24/7
                          prioritario
                        </li>
                      </ul>
                    </div>
                    <div className="pricing-btn">
                      <Link
                        to="https://www.mercadopago.com.pe/subscriptions/checkout?preapproval_plan_id=2c9380849146ff8b01914a4c78d1017b"
                        className="btn"
                      >
                        Seleccionar este plan
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="tab-pane"
              id="year"
              role="tabpanel"
              aria-labelledby="year-tab"
            >
              <div className="row g-0 align-items-center justify-content-center">
                <div className="col-lg-4 col-md-6">
                  <div className="pricing-item">
                    <div className="pricing-icon">
                      <img src="/img/icon/pricing_icon01.png" alt="" />
                    </div>
                    <div className="pricing-top">
                      <h2 className="title">Básico</h2>
                      <p>Al pagar anualmente, ahorra un 25%</p>
                    </div>
                    <div className="pricing-price">
                      <h2 className="price">
                        <span>S/</span>890<strong>/Año</strong>
                      </h2>
                    </div>
                    <div className="pricing-list">
                      <h4 className="title">Incluye:</h4>
                      <ul className="list-wrap">
                        <li>
                          <i className="far fa-check"></i>Página web informativa
                        </li>
                        <li>
                          <i className="far fa-check"></i>Diseño adaptable a
                          móviles
                        </li>
                        <li>
                          <i className="far fa-check"></i>Seguridad SSL (https)
                        </li>
                        <li>
                          <i className="far fa-check"></i>Enlaces a redes
                          sociales
                        </li>
                        <li>
                          <i className="far fa-check"></i>1 correo corporativo
                        </li>
                        <li>
                          <i className="far fa-check"></i>Creación de redes
                          sociales
                        </li>
                        <li>
                          <i className="far fa-check"></i>Diseño de logo básico
                        </li>
                        <li>
                          <i className="far fa-check"></i>Registro negocio en
                          Google Maps
                        </li>
                        <li>
                          <i className="far fa-check"></i>Integración con
                          WhatsApp
                        </li>
                        <li>
                          <i className="far fa-check"></i>Optimización SEO
                        </li>
                        <li>
                          <i className="far fa-check"></i>Capacitación de uso
                          del sitio web
                        </li>
                      </ul>
                    </div>
                    <div className="pricing-btn">
                      <Link
                        to="https://www.mercadopago.com.pe/subscriptions/checkout?preapproval_plan_id=2c93808491d6d45e0191ddfb64910331"
                        className="btn"
                      >
                        Seleccionar este plan
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6">
                  <div className="pricing-item active">
                    <div className="pricing-icon">
                      <img src="/img/icon/pricing_icon02.png" alt="" />
                    </div>
                    <div className="pricing-top">
                      <h2 className="title">Estándar</h2>
                      <p>Al pagar anualmente, ahorra un 30%</p>
                    </div>
                    <div className="pricing-price">
                      <h2 className="price">
                        <span>S/</span>1250<strong>/Año</strong>
                      </h2>
                    </div>
                    <div className="pricing-list">
                      <h4 className="title">Incluye:</h4>
                      <ul className="list-wrap">
                        <li>
                          <i className="far fa-check"></i>Más Todo lo del plan
                          Básico
                        </li>
                        <li>
                          <i className="far fa-check"></i>Dominio.com gratis por
                          1 año
                        </li>
                        <li>
                          <i className="far fa-check"></i>Tienda en línea
                        </li>
                        <li>
                          <i className="far fa-check"></i>Gestión de productos
                        </li>
                        <li>
                          <i className="far fa-check"></i>Hasta 5 correos
                          corporativos
                        </li>
                        <li>
                          <i className="far fa-check"></i>Campaña básica de
                          publicidad
                        </li>
                        <li>
                          <i className="far fa-check"></i>Creación de pixel de
                          facebook
                        </li>
                        <li>
                          <i className="far fa-check"></i>Google Analytics
                        </li>
                        <li>
                          <i className="far fa-check"></i>Cupones y descuentos
                        </li>
                        <li>
                          <i className="far fa-check"></i>Configuración de chat
                          en vivo
                        </li>
                        <li>
                          <i className="far fa-check"></i>Integración de pago
                          locales (según país - Tarjetas, Yape)
                        </li>
                      </ul>
                    </div>
                    <div className="pricing-btn">
                      <Link
                        to="https://www.mercadopago.com.pe/subscriptions/checkout?preapproval_plan_id=2c93808491d6d4130191ddfc9409033a"
                        className="btn"
                      >
                        Seleccionar este plan
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 col-md-6">
                  <div className="pricing-item">
                    <div className="pricing-icon">
                      <img src="/img/icon/pricing_icon03.png" alt="" />
                    </div>
                    <div className="pricing-top">
                      <h2 className="title">Premium</h2>
                      <p>Al pagar anualmente, ahorra un 35%</p>
                    </div>
                    <div className="pricing-price">
                      <h2 className="price">
                        <span>S/</span>1550<strong>/Año</strong>
                      </h2>
                    </div>
                    <div className="pricing-list">
                      <h4 className="title">Incluye:</h4>
                      <ul className="list-wrap">
                        <li>
                          <i className="far fa-check"></i>Más Todo lo del plan
                          Estándard
                        </li>
                        <li>
                          <i className="far fa-check"></i>Control de inventario
                        </li>
                        <li>
                          <i className="far fa-check"></i>Sistema de punto de
                          venta (POS)
                        </li>
                        <li>
                          <i className="far fa-check"></i>Gestión de clientes
                        </li>
                        <li>
                          <i className="far fa-check"></i>Reportes detallados
                        </li>
                        <li>
                          <i className="far fa-check"></i>Correos corporativos
                          ilimitados
                        </li>
                        <li>
                          <i className="far fa-check"></i>Diseño de logo
                          avanzado
                        </li>
                        <li>
                          <i className="far fa-check"></i>Google Ads y Facebook
                          Ads
                        </li>
                        <li>
                          <i className="far fa-check"></i>Asesoría marketing
                          digital
                        </li>
                        <li>
                          <i className="far fa-check"></i>Sistema de lealtad
                          para clientes
                        </li>
                        <li>
                          <i className="far fa-check"></i>Soporte 24/7
                          prioritario
                        </li>
                      </ul>
                    </div>
                    <div className="pricing-btn">
                      <Link
                        to="https://www.mercadopago.com.pe/subscriptions/checkout?preapproval_plan_id=2c93808491d6d45e0191ddfe9c810333"
                        className="btn"
                      >
                        Seleccionar este plan
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PricingArea;
