import React from "react";
import SlickSlider from "../SlickSlider/SlickSlider";
import TestimonialAreaTwoItem from "./TestimonialAreaTwoItem";

const TestimonialAreaTwo = () => {
  const slick_settings = {
    dots: true,
    infinite: false,
    speed: 1000,
    autoplay: true,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  const testimonial_items = [
    {
      desc: `‘’Desde el primer contacto, el equipo mostró gran profesionalismo. El proyecto de desarrollo web fue entregado a tiempo y con todas las funcionalidades que necesitábamos. Estamos muy contentos con el trabajo.”`,
      title: "Andy Chesve",
      designation: "Gerente - www.Spaceclo.com",
      src: "/img/images/testimonial_avatar01.png",
    },
    {
      desc: `‘’La experiencia fue muy positiva. El equipo entendió perfectamente nuestras necesidades y propuso soluciones innovadoras. El soporte post-lanzamiento también ha sido excelente.”`,
      title: "Ana F.",
      designation: "Dueña de www.Anacleta.cl",
      src: "/img/images/testimonial_avatar02.png",
    },
    {
      desc: `‘’Excelentes profesionales. Trabajan rápido y entregan resultados de alta calidad. Nos ayudaron a crear nuestro sitio web y los resultados han sido fantásticos. Muy recomendables.”`,
      title: "Davis Toribio Horna",
      designation: "Gerente - Nano performance EIRL",
      src: "/img/images/testimonial_avatar03.png",
    },
    {
      desc: `‘’Profesionales y eficaces. El proceso de desarrollo web fue fluido y el resultado final es impresionante. Apreciamos la atención personalizada y el seguimiento constante durante el proyecto.”`,
      title: "Sofía Rojas",
      designation: "Contadora",
      src: "/img/images/testimonial_avatar04.png",
    },
    {
      desc: `‘’El trabajo realizado fue excelente. La calidad del desarrollo y el diseño del sitio web son muy buenos. Nos brindaron un excelente soporte y estuvieron siempre disponibles para resolver nuestras dudas.”`,
      title: "Paola Jimenez",
      designation: "Nutricionista",
      src: "/img/images/testimonial_avatar02.png",
    },
    {
      desc: `‘’Fue un placer trabajar con este equipo. La atención al cliente fue excelente y el proyecto se entregó según lo prometido. La calidad del trabajo superó nuestras expectativas.”`,
      title: "Leticia Del Carmen",
      designation: "Abogada",
      src: "/img/images/testimonial_avatar04.png",
    },
  ];

  return (
    <section className="testimonial-area-two pt-110 pb-50">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-9">
            <div className="section-title title-style-two white-title text-center mb-60">
              <span className="sub-title">Lo Que Dicen Nuestros Clientes</span>
              <h2 className="title">Testimonios</h2>
            </div>
          </div>
        </div>

        <div className="row testimonial-active-two">
          <SlickSlider settings={slick_settings}>
            {testimonial_items.map((x, index) => (
              <div key={index} className="col-lg-3">
                <TestimonialAreaTwoItem item={x} />
              </div>
            ))}
          </SlickSlider>
        </div>
      </div>
    </section>
  );
};

export default TestimonialAreaTwo;
