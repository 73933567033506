import React from "react";
import CounterAreaItem from "./CounterAreaItem ";

const CounterArea = () => {
  return (
    <div className="counter-area">
      <div className="container">
        <div className="counter-item-wrap">
          <div className="row">
            <div className="col-lg-3 col-sm-6">
              <CounterAreaItem amount={9} info={["Proyectos", "Completados"]} />
            </div>

            <div className="col-lg-3 col-sm-6">
              <CounterAreaItem amount={9} info={["Clientes", "Felices"]} />
            </div>

            <div className="col-lg-3 col-sm-6">
              <CounterAreaItem amount={9} info={["Clientes", "Activos"]} />
            </div>

            <div className="col-lg-3 col-sm-6">
              <CounterAreaItem amount={88} info={["Tazas", "de Café"]} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CounterArea;