import React, { useState } from "react";
import ProjectAreaTwoItem from "./ProjectAreaTwoItem";
import cn from "classnames";

const ProjectAreaTwo = () => {
  const project_items = [
    {
      url: "https://nanoperformance.com.pe",
      src: "/img/project/nanoperformance.webp",
      title: "Nanoperformance.com.pe",
      desc: "Nano Performance se especializa en llevar tu automóvil a otro nivel, con servicios integrales que cubren todo lo que necesitas para mantener tu coche en perfecto estado",
      className: "cat-one",
    },
    {
      url: "https://mueblescustom.cl",
      src: "/img/project/mueblescustom.webp",
      title: "Mueblescustom.cl",
      desc: "Muebles Custom son expertos en la fabricación de muebles a medida para cocinas, closets, oficinas y más en Santiago de Chile.",
      className: "cat-one",
    },
    {
      url: "https://spaceclo.com",
      src: "/img/project/spaceclo.webp",
      title: "Spaceclo.com",
      desc: "Space es una marca de ropa de calle online con sede en Trujillo, Perú, que ofrece ropa de lujo a precios razonables.",
      className: "cat-two",
    },
    {
      url: "https://anacleta.online",
      src: "/img/project/anacleta.online.webp",
      title: "Anacleta.online",
      desc: "Sistema de Taller a Medida y Control de inventario para Anacleta Chile",
      className: "cat-three",
    },

  ];

  const filters = [
    {
      filter: "*",
      label: "Ver Todo",
    },
    {
      filter: "cat-one",
      label: "Desarrollo Web",
    },
    {
      filter: "cat-two",
      label: "Tiendas Virtuales",
    },
    {
      filter: "cat-three",
      label: "Software a medida",
    },
    {
      filter: "cat-four",
      label: "Gestión de RRSS",
    },
    {
      filter: "cat-five",
      label: "Product Design",
    },
  ];

  const [selectedFilter, setSelectedFilter] = useState("*");

  const [items, setItems] = useState(project_items);

  const handleFilterChange = (filter) => {
    const newItems = project_items.filter((el) =>
      el.className.split(" ").includes(filter)
    );
    setSelectedFilter(filter);
    setItems(filter === "*" ? project_items : newItems);
  };

  return (
    <section className="project-area-two pt-110 pb-90">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-9">
            <div className="section-title title-style-two white-title mb-60 text-center">
              <span className="sub-title">Casos de éxito</span>
              <h2 className="title">Portfolio</h2>
              <p>
                Aquí encontrarás una muestra de algunos proyectos que hemos
                desarrollado, todos diseñados para maximizar el potencial de
                cada negocio.
              </p>
            </div>
          </div>
        </div>

        <div className="project-item-wrap">
          <div className="row">
            <div className="col-lg-12">
              <div className="project-menu-nav">
                {filters.map((x, index) => (
                  <button
                    key={index}
                    className={x.filter === selectedFilter ? "active" : ""}
                    data-filter={x.filter}
                    onClick={() => handleFilterChange(x.filter)}
                  >
                    {x.label}
                  </button>
                ))}
              </div>
            </div>
          </div>

          <div className="row project-active-two">
            {items.map((x, index) => (
              <div
                key={index}
                className={cn(
                  "col-lg-4 col-md-6 grid-item grid-sizer  wow fadeInUp",
                  x.className
                )}
              >
                <ProjectAreaTwoItem item={x} />
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="project-shape">
        <img src="/img/project/h2_project_shape.png" alt="" />
      </div>
    </section>
  );
};

export default ProjectAreaTwo;
